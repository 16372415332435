<template>
  <div
    v-for="(tab, index) in tabs"
    :key="tab.key || keyFn?.(tab)"
    class="flex w-full flex-col"
  >
    <NuxtLink
      v-if="tab.to"
      v-slot="{ isActive, isExactActive }"
      :to="tab.to"
      :aria-label="tab.title"
    >
      <NavVerticalTab
        :="{
          tab,
          index,
          isActive: checkActive(tab, exactActive ? isExactActive : isActive),
          unresponsive
        }"
      />
    </NuxtLink>
    <NavVerticalTab
      v-else
      :="{
        is: 'button',
        tab,
        index,
        isActive: checkActive(tab),
        unresponsive
      }"
    />
  </div>
</template>

<script setup lang="ts" generic="T extends Primitive">
// defines
defineOptions({ inheritAttrs: false })
const { keyFn, activeTab } = defineProps<{
  tabs: NavItem[]
  keyFn?: KeyFn<NavItem>
  activeTab?: T
  unresponsive?: boolean
  exactActive?: boolean
}>()

// methods
const checkActive = (tab: NavItem, navActive?: boolean) => {
  if (!activeTab || (!tab.key && !keyFn)) return !!navActive
  return (tab.key || keyFn?.(tab)) === activeTab
}
</script>
